<template>
  <div id="intelligenceManager" v-if="loaded">
    <!-- v-if="!videoList.video_id" -->
    <div
      class="stand_center"
      :style="
        videoList.status && !videoList.video_name
          ? 'height: calc(100vh - 100px);'
          : 'height: calc(100vh - 152px);'
      "
    >
      <div
        class="select_video"
        v-if="videoList.status && !videoList.video_name"
      >
        <div class="maincontain">
          <img src="~@ass/img/1.4.3.8/img_nrgl_ksp@2x.png" alt="" />
          <div class="tips">请添加一个视频作为智能直播的内容！</div>
          <el-button
            type="primary"
            style="width: 240px; height: 50px"
            @click="dialogSelectVisible = true"
          >
            添加视频
          </el-button>
        </div>
      </div>
      <div v-else class="setting">
        <div class="video">
          <div class="header">
            <div class="title">预览视频：{{ videoList.video_name }}</div>
            <el-button
              v-if="videoList.status != 1"
              class="bt1"
              style="width: 116px"
              type="text"
              @click="dialogSelectVisible = true"
            >
              替换视频
            </el-button>
          </div>
          <div class="videocontain">
            <!--视频预览-->
            <template v-if="previewSrc">
              <previewVideo
                :lowerIndex="true"
                v-if="previewSrc"
                :src="previewSrc"
                :options="{ autoplay: false }"
                @close="preViewVideo"
              ></previewVideo>
            </template>
          </div>
        </div>
        <sendMessageSetting
          v-if="!isMessageTemplate"
          :videoList="videoList"
          @getData="getAiContent"
        ></sendMessageSetting>
      </div>
    </div>

    <el-button id="popo-btn" ref="popo" />

    <prophesy
      @robotSpeaksuccess="robotSpeaksuccess"
      v-if="prophesyStatus"
      :dialogstatus.sync="prophesyStatus"
      :chapter_hour="videoList.chapter_hour"
    ></prophesy>

    <!-- 选择视频 由于智能选择未加密的视频，所以要区分一下-->
    <fileListAndFileUpload
      jiamiType="2"
      v-if="dialogSelectVisible"
      title1="请选择视频(单选)"
      prompt="提示：系统自动过滤已加密的视频，仅支持未加密的视频；选择后请勿加密视频，否则无法播放！"
      @close="dialogSelectVisible = false"
      :singgleType="1"
      :showAudioMenu="false"
      @complete="gbDialog_data"
      :tabList="tabList"
    ></fileListAndFileUpload>
  </div>
</template>

<script>
import prophesy from './prophesy'
import previewVideo from '@cm/coursePop/previewVideo'
import fileListAndFileUpload from '@/components/fileListAndFileUpload'
import sendMessageSetting from './sendMessageSetting'
export default {
  name: 'intelligenceManager',
  
  components: {
    fileListAndFileUpload,
    previewVideo,
    prophesy,
    sendMessageSetting,
  },
  props:{
    isMessageTemplate:Boolean,
  },
  data() {
    return {
      tabList: ['录播视频', '直播回放视频', '公开课回放视频'],

      insertButton: false,

      informationEmpty: false,

      infomationList: [],

      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() - 8.64e7 + 180000 //设置选择今天以及今天之后的日期
        },
      },

      time: '',

      prophesyStatus: false,

      previewSrc: '',

      videoList: {},

      dialogSelectVisible: false,

      loaded: false,
    }
  },

  created() {
    this.getAiContent()
  },

  destroyed() {
    clearInterval(this.setTimer)
  },

  methods: {
    reset() {
      this.$http({
        url: '/openClass/setLiveStatus',
        data: {
          open_class_id: this.$route.query.open_class_id,
        },
        callback: () => {
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })

          this.getAiContent()
        },
      })
    },

    robotSpeaksuccess() {
      this.getAiContent()
    },

    //   请求数据
    getAiContent() {
      this.$http({
        url: '/openClass/aiContent',
        data: {
          open_class_id: this.$route.query.open_class_id,
        },
        callback: ({ data }) => {
          this.videoList = data
          // status 1 直播中 2未开始 3已结束

          // 开始播放视频
          if (this.videoList.video_url) {
            this.preViewVideo(this.videoList.video_url)
          }

          // 开始直播结束倒计时
          clearInterval(this.setTimer)
          if (this.videoList.r_time > 0) {
            this.setTimer = setInterval(() => {
              if (this.videoList.r_time > 0) {
                this.videoList.r_time--
              } else {
                clearInterval(this.setTimer)
                this.getAiContent()
              }
            }, 1000)
          }
          this.loaded = true
        },
      })
    },

    // 预览视频
    preViewVideo(src) {
      this.previewSrc = typeof src === 'string' ? src : ''
    },

    // 选择/替换视频
    async gbDialog_data(list) {
      await this.$http({
        url: '/openClass/selectVideo',
        data: {
          open_class_id: this.$route.query.open_class_id,
          video_id: list.video_id,
          recording_id: list.recording_id,
        },
      })
      this.getAiContent()
    },
  },
}
</script>

<style lang="scss" >
.intelligenceManager_date_picker {
  .el-button--text:nth-child(2) {
    display: none;
  }
  .cancel {
    font-size: 12px;
    color: #ff3535;
    border: 0;
    &.el-button:hover {
      background-color: #fff !important;
    }
  }
}
</style>

<style lang="scss" scoped>
#popo-btn {
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin: 0;
}
.datepicker {
  ::v-deep .el-input__inner {
    &::placeholder {
      font-size: 14px;
      color: #4b4b4b;
    }
  }
}
#intelligenceManager {
  ::v-deep .el-button--danger {
    color: #ffffff;
    background-color: #ff3535ff;
    border-color: #ff3535ff;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .bottom_btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: $menuWidth;
    height: 70px;
    width: calc(100% - 188px);
    box-sizing: border-box;
    padding: 0 40px;
    background: #ffffff;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.07);
    .hadtime {
      margin-right: 20px;
      .status {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      .time {
        margin-top: 8px;
        font-size: 12px;
        color: #999999;
      }
    }
    .el-button {
      width: 140px;
    }
    .bt1,
    .bt2 {
      border: #0aa29b 1px solid;
      color: #0aa29b;
    }
    .bt2 {
      margin-right: 10px;
    }
  }
  .stand_center {
    display: flex;
    justify-content: center;
    .setting {
      width: 100%;
      display: flex;
      height: 98%;
      .video {
        width: calc(100% - 294px);
        min-width: 640px;
        height: 100%;
        .header {
          display: flex;
          align-items: center;
          height: 36px;
          background: #1e1e1e;
          .title {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 13px;
            color: #ffffff;
            padding-left: 20px;
            line-height: 36px;
          }
        }

        ::v-deep .videocontain {
          height: calc(100% - 36px);
          width: 100%;
          .preview-video {
            height: 100%;
            position: relative;
            top: 0;
            left: 0;
            margin-left: 0;
            width: 100%;
            .headDowndText {
              display: none;
            }
            .video {
              height: 100%;
              width: 100%;
            }
            .video-js .vjs-play-progress {
              background-color: #1b9d97;
            }
          }
        }
      }
    }
  }
  .select_video {
    width: 100%;
    height: 100%;
    background-color: #232323;
    .maincontain {
      box-sizing: border-box;
      padding-top: 25%;
      text-align: center;
      img {
        margin: 0 auto;
        width: 136px;
        height: auto;
      }
      .tips {
        font-size: 14px;
        color: #ffffff;
        margin: 30px auto;
      }
    }
  }
}
</style>