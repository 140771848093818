<template>
  <div class="chatcontain">
    <!---设置完成 展示结果界面-->
    <template
      v-if="
        (videoList.status != 3 && videoList.start_time > 0) ||
          (videoList.task_status == 2 && videoList.msg_id > 0)
      "
    >
      <div class="item2">
        开播时间：{{
          videoList.start_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
        }}
      </div>
      <div class="item2">直播状态：{{ videoList.status | getLiveStatus }}</div>
      <div class="item2">历史消息模板：{{ videoList.msgName || '无' }}</div>
      <div class="item2 last">
        首条消息发送时间：
        <template v-if="videoList.msgName && videoList.task_time > 0">
          <span class="red">
            {{ videoList.task_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
          </span>
          <el-button
            class="stop-btn"
            type="text"
            @click="stopSendMsg"
            v-if="videoList.task_status == 2"
          >
            停止发送
          </el-button>
          <el-button class="send-btn" type="text" @click="startSendMsg" v-else>
            启动发送
          </el-button>
        </template>
        <template v-else>
          <span class="red">- -</span>
        </template>
      </div>
      <div class="save-btn">
        <el-button @click="stopLive">停止直播</el-button>
      </div>
    </template>
    <!---设置界面-->
    <template v-else>
      <div class="item">
        <div class="title">开播时间</div>
        <div class="radio-group">
          <!-- <el-radio-group v-model="formData.startType">
            <el-radio :label="1">定时</el-radio>
            <el-radio :label="2">立即开播</el-radio>
          </el-radio-group> -->
          <el-date-picker
            class="select-item"
            style="width: 258px"
            v-model="formData.start_time"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            placeholder="请选择开播时间"
            :picker-options="pickerOptions"
            prefix-icon="null"
          ></el-date-picker>
        </div>
      </div>
      <div class="item">
        <div class="title">话术本-聊天记录</div>
        <el-select
          v-model="formData.msg_id"
          placeholder="选择话术本-聊天记录"
          class="select-item"
        >
          <el-option
            v-for="item in templateList"
            :key="item.msg_id"
            :label="item.name"
            :value="item.msg_id"
          ></el-option>
        </el-select>
        <div class="reset" @click="resetTemplate">清空</div>
      </div>
      <div class="item item-2">
        <div class="title">消息时移</div>
        <div class="radio-group">
          <el-radio-group v-model="formData.move_sort">
            <el-radio label="1">前移</el-radio>
            <el-radio label="2">后移</el-radio>
          </el-radio-group>
          <div class="receive_duration_date">
            <el-input-number
              v-model="receive_duration_date.hour"
              controls-position="right"
              :min="0"
              :max="23"
            ></el-input-number>
            <span class="fs14 ml10 mr10">时</span>
            <el-input-number
              v-model="receive_duration_date.minute"
              controls-position="right"
              :min="0"
              :max="59"
            ></el-input-number>
            <span class="fs14 ml10 mr10">分</span>
            <el-input-number
              v-model="receive_duration_date.second"
              controls-position="right"
              :min="0"
              :max="59"
            ></el-input-number>
            <span class="fs14 ml10">秒</span>
          </div>
          <div class="first-msg-time" v-if="firstMsgTime">
            首条消息发送时间：
            <span class="red">
              {{ firstMsgTime | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
            </span>
          </div>
          <div class="first-msg-time" v-else>
            首条消息发送时间：
            <span class="red">- -</span>
          </div>
          <div
            class="first-msg-time"
            v-if="firstMsgTime && recommendedTime.val !== 0"
          >
            <span>时移到与开播时间一致：</span>
            <helpIcon
              content="选择之后将把首条消息发送的时间，时移到与开播时间一致"
              style="margin-right:7px;"
            ></helpIcon>
            <span class="red">
              {{ recommendedTime.move_sort == 1 ? '前移' : '后移' }}
              {{ recommendedTime.val | formatTimeLength2('hh时mm分ss秒') }}
            </span>
            <span
              data-v-7608b991=""
              style="
                margin-left: 10px;
                cursor: pointer;
                text-decoration: underline;
                color: #f39700;
              "
              @click="selectRecommendedTime"
            >
              选择
            </span>
          </div>
          <div class="prompt">
            允许设置值：时<=23,分<=59,秒<=59
            <br />
            时移和日期无关，只需对比一天内具体时间
            <br />
            例如：历史消息模板里的第一条消息发送时间是15:00:00，今天的直播时间是20:00:00，这时候需要将发送时间向后移5小时。
          </div>
        </div>
      </div>
      <div class="save-btn">
        <el-button @click="save">开始直播</el-button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'sendMessageSetting',

  props: {
    videoList: Object,
  },

  components: {},

  data() {
    return {
      receive_duration_date: {
        hour: 0,
        minute: 0,
        second: 0,
      },
      formData: Object.assign(
        {},
        {
          move_sort: '1',
          msg_id: '',
          start_time: '',
        },
        this.videoList,
        {
          start_time:
            this.videoList.start_time > 0
              ? this.videoList.start_time * 1000
              : '',
          move_sort: this.videoList.move_sort
            ? `${this.videoList.move_sort}`
            : '1',
          msg_id: this.videoList.msg_id > 0 ? this.videoList.msg_id : '',
        }
      ),

      firstMsgTime: '',

      templateList: [],

      recommendedTime: {
        val: '',
      },

      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() - 8.64e7 //设置选择今天以及今天之后的日期
        },
      },
    }
  },

  watch: {
    receive_duration_date: {
      handler() {
        this.getFirstMsgTime()
      },
      deep: true,
    },

    formData: {
      handler() {
        this.receive_duration_date = {
          hour: 0,
          minute: 0,
          second: 0,
        }
        this.getFirstMsgTime()
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    requestData() {
      return {
        msg_id: this.formData.msg_id,
        start_time: this.formData.start_time / 1000,
        move_sort: this.formData.move_sort,
        move_time:
          Number(this.receive_duration_date.hour * 3600) +
          Number(this.receive_duration_date.minute * 60) +
          Number(this.receive_duration_date.second),
        open_class_id: this.$route.query.open_class_id,
      }
    },

    // 推荐时移
    // recommendedTime() {
    //   if (this.firstMsgTime) {
    //     const startTime =
    //       this.formData.startType == 1 ? this.formData.startTime : Date.now()
    //     // 正值说明 需要向前偏移 开播时间大于第一条消息发送时间
    //     // 负值说明 需要向后偏移 第一条消息发送时间小于开播时间
    //     const val = parseInt(this.firstMsgTime - startTime / 1000)
    //     return {
    //       val: Math.abs(val),
    //       move_sort: val < 0 ? 2 : 1,
    //     }
    //   } else {
    //     return {
    //       val: '',
    //     }
    //   }
    // },
  },

  filters: {
    getLiveStatus(status) {
      let text
      switch (Number(status)) {
        case 1:
          text = '直播中'
          break
        case 2:
          text = '未开始'
          break
        case 3:
          text = '已结束'
          break
        default:
          text = '- -'
      }

      return text
    },
  },

  created() {
    this.getTemplateList()
  },

  methods: {
    // 获取模板列表
    async getTemplateList() {
      const { data } = await this.$http({
        url: '/chatMessage/templateList',
      })

      this.templateList = data
    },

    // 保存设置
    async save() {
      if (!this.requestData.start_time) {
        this.$root.prompt('请选择开播时间')
        return
      }
      await this.$http({
        url: '/openClass/timedPlay',
        data: this.requestData,
      })

      this.$emit('getData')
    },

    // 获取首条发送时间
    getFirstMsgTime() {
      if (this.formData.msg_id && this.formData.start_time) {
        this.$http({
          url: '/openClass/sendTime',
          data: this.requestData,
        }).then(({ data }) => {
          this.firstMsgTime = data.task_time
          this.recommendedTime = {
            val: data.recommend_time,
            move_sort: data.move_sort,
          }
        })
      }
    },

    // 选择时移时长
    selectRecommendedTime() {
      this.formData.move_sort = this.recommendedTime.move_sort
      // 延迟是因为 formData 参数的变化回去重置时移时长 不延迟选择建议时移就会无效果
      setTimeout(() => {
        const val = this.$options.filters
          .formatTimeLength2(this.recommendedTime.val, 'hh:mm:ss')
          .split(':')
        this.receive_duration_date = {
          hour: val[0],
          minute: val[1],
          second: val[2],
        }
      }, 100)
    },

    // 清空模板
    resetTemplate() {
      this.formData.msg_id = ''
      this.firstMsgTime = ''
    },

    success() {
      this.$root.prompt({
        msg: '操作成功',
        type: 'success',
      })
      this.$emit('getData')
    },

    error() {
      this.$root.prompt('操作失败')
    },

    // 启动发送消息
    async startSendMsg() {
      try {
        await this.$http({
          url: '/openClass/openMsgTask',
          data: {
            open_class_id: this.$route.query.open_class_id,
          },
        })
        this.success()
      } catch (e) {
        this.error()
      }
    },

    // 停止发送
    async stopSendMsg() {
      try {
        await this.$http({
          url: '/openClass/endMsgTask',
          data: {
            open_class_id: this.$route.query.open_class_id,
          },
        })
        this.success()
      } catch (e) {
        this.error()
      }
    },

    // 停止直播
    async stopLive() {
      try {
        this.$http({
          url: '/openClass/endMsgTask',
          data: {
            open_class_id: this.$route.query.open_class_id,
          },
        })
        await this.$http({
          url: '/openClass/endLive',
          data: {
            open_class_id: this.$route.query.open_class_id,
          },
        })
        this.success()
      } catch (e) {
        this.error()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.chatcontain {
  width: 439px;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  padding: 44px 0 20px;
  background-color: #353535;
  .item {
    padding: 0 20px;
    display: flex;
    margin-bottom: 30px;
    &.item-2 {
      padding-right: 10px;
    }
    .title {
      min-width: 78px;
      margin-right: 16px;
      font-size: 13px;
      color: #ffffff;
      line-height: 1.5;
    }
    .reset {
      white-space: nowrap;
      margin-left: 10px;
      font-size: 13px;
      align-self: center;
      color: #f39700;
      cursor: pointer;
      padding: 10px 0;
      text-decoration: underline;
    }
    .radio-group {
      flex: 1;
      .el-radio-group {
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        .el-radio {
          color: #fff;
          &:first-child {
            margin-right: 51px;
          }
        }
      }
    }
    .select-item {
      width: 100% !important;
      display: block;
      ::v-deep .el-input__inner {
        border: 0;
        height: 40px;
        color: #fff;
        border-radius: 4px;
        font-size: 14px;
        padding-left: 14px;
        background: #666666;
        &::placeholder {
          color: #afafaf;
          font-size: 14px;
        }
      }
    }
  }
  .item2 {
    font-size: 13px;
    color: #ffffff;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    &.last {
      margin: -10px 0 0 0;
    }
  }

  ::v-deep .send-btn,
  ::v-deep .stop-btn {
    width: 74px;
    height: 36px;
    color: #fff;
    padding: 0;
    border: 0;
    margin-left: 10px;
    font-size: 14px;
    background: #666666;
    border-radius: 4px;
    line-height: 36px;
  }
  ::v-deep .send-btn {
    background: #0aa29b;
  }
  .receive_duration_date {
    color: #fff;
    display: flex;
    align-items: center;
    ::v-deep .el-input-number {
      width: auto;
      background: none;
      border: 0;
    }
    ::v-deep .el-input-number__decrease,
    ::v-deep .el-input-number__increase {
      width: auto;
      border: 0;
      background: none;
      line-height: 15px;
    }
    ::v-deep .el-input-number__decrease {
      bottom: 9px;
      padding-right: 5px;
      line-height: 13px;
    }
    ::v-deep .el-input-number__increase {
      top: 8px;
      padding-right: 5px;
      line-height: 13px;
    }
    ::v-deep .el-icon-arrow-up:before {
      content: '';
      color: #fff;
    }
    ::v-deep .el-icon-arrow-down:before {
      content: '';
      color: #fff;
    }
    ::v-deep .el-input__inner {
      border: 0;
      color: #fff;
      background: #666;
      border-radius: 4px;
      font-size: 14px;
      padding: 0 12px 0 0;
    }
  }
  .first-msg-time {
    font-size: 13px;
    color: #ffffff;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .prompt {
    font-size: 12px;
    color: #999999;
    margin-top: 20px;
    line-height: 1.6;
  }
  .save-btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
    ::v-deep .el-button {
      width: 74px;
      height: 36px;
      color: #fff;
      padding: 0;
      border: 0;
      font-size: 14px;
      background: #ff3535;
      border-radius: 4px;
      line-height: 36px;
    }
  }
  .red {
    color: #ff3535;
  }
}
</style>
