<template>
  <el-dialog
    title="添加机器人"
    class="dialogVisible"
    width="800px"
    :visible="dialogstatus"
    @update:visible="v => $emit('update:dialogstatus', v)"
    v-if="dialogstatus"
    :destroy-on-close="true"
    @close="cancel"
  >
    <div slot="title" class="title">
      <div class="sp_1">机器人</div>
      <div class="sp_2">提示：已添加的机器人不在列表展示</div>
    </div>
    <div class="dialogVisible_bottom">
      <div class="search">
        <div>
          <el-button
            type="primary"
            @click="gotorobot"
            size="medium"
            class="mr20"
          >
            去新建机器人
          </el-button>
          <el-button type="text" @click="getRobotList">刷新列表</el-button>
        </div>
        <el-input
          style="width: 218px"
          v-model="post.search"
          size="medium"
          placeholder="输入名称搜索"
        ></el-input>
      </div>
      <el-table
        @row-click="rowClick"
        ref="dialogTable"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333',
        }"
        :data="tableData"
        @selection-change="handleSelectionChange"
        class="dialogTable"
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="45"></el-table-column>

        <el-table-column label="机器人">
          <template slot-scope="{ row }">
            <div class="tu_name">
              <div class="picture">
                <img :src="row.photo" alt="" />
              </div>
              <span class="name">{{ row.name }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="创建人" prop="uname"></el-table-column>

        <el-table-column label="创建时间" sortable="custom">
          <template slot-scope="{ row }">
            {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel" size="medium">取 消</el-button>
      <el-button type="primary" size="medium" @click="submit">
        添加 ({{ selected.length }})
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')
export default {
  name: 'robot',
  data() {
    return {
      selected: [],

      tableData: [],

      post: {
        status: 1,
        search: '',
        sort: '',
        open_class_id: '',
      },
    }
  },

  watch: {
    'post.search'() {
      this.getRobotList()
    },
  },

  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(['openClassId']),
  },

  created() {
    this.getRobotList()
  },

  methods: {
    gotorobot() {
      let url = this.$router.resolve({
        path: '/liveRobot',
      })
      this.$store.dispatch('open', url.href)
    },
    rowClick(row) {
      this.$refs.dialogTable.toggleRowSelection(row)
    },

    submit() {
      if (this.selected.length == 0) {
        this.$root.prompt('请先勾选需要添加的机器人！')
        return
      }
      this.$emit('robotSelectVal', this.selected)
      this.cancel()
    },

    // 基础数据获取
    getRobotList() {
      this.post = _.assign({}, this.post, {
        open_class_id: this.openClassId || this.$route.query.open_class_id,
      })
      this.$http({
        url: '/liveRobot/robotList',
        data: this.post,
        callback: ({ data }) => {
          this.tableData = data
        },
      })
    },

    //  排序
    sortChange(val) {
      this.post = _.assign({}, this.post, {
        sort: !val.order ? '' : val.order === 'ascending' ? 1 : 2,
      })

      this.getRobotList()
    },

    // 多选
    handleSelectionChange(val) {
      this.selected = val
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 2px !important;
  height: 8px !important;
  background-color: #fff !important;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
  background-color: #fff !important;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #ccc !important;
}
.dialogTable {
  height: 300px;
  overflow-y: auto;
}
::v-deep .el-dialog {
  height: 494px !important;
  .el-dialog__body {
    height: 353px !important;
    overflow: hidden;
  }
}
.dialogVisible {
  .dialogVisible_bottom {
    .search {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
  .tu_name {
    display: flex;
    align-items: center;
    .picture {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      width: calc(100% - 30px);
    }
  }
  .status {
    .greencolor {
      color: #42c244;
    }
    .redcolor {
      color: #ff3535;
    }
  }

  .title {
    display: flex;
    align-items: center;
    .sp_1 {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-right: 14px;
    }
    .sp_2 {
      font-size: 12px;
      color: #ff3535;
    }
  }
}
</style>
