<template>
  <div id="prophesy">
    <el-dialog
      class="dialogVisibleprophesy"
      width="800px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      @close="cancel"
    >
      <div slot="title" class="title">
        <span>预发言</span>
      </div>
      <div class="dialogVisible_bottom">
        <div class="mask" v-if="isshow"></div>
        <el-button
          type="primary"
          size="medium"
          class="mb20"
          @click="robotSelectStatus = true"
        >
          添加机器人
        </el-button>
        <div class="header">
          <div class="d_1 ml20">机器人</div>
          <div class="d_4">发送时间</div>
          <div class="d_2">发送内容</div>
          <div class="d_3">操作</div>
        </div>
        <div class="main_contain">
          <template v-if="newRobotVal.length > 0">
            <div
              class="message_contain"
              v-for="(item, index) in newRobotVal"
              :key="index"
            >
              <div class="d_1 mt10">
                <div class="photo">
                  <img :src="item.photo" alt="" />
                </div>
                <div class="name" :title="item.name">{{ item.name }}</div>
              </div>

              <el-popover
                @hide="popoverHide"
                @show="popoverShow($event, index)"
                placement="bottom"
                trigger="click"
                width="400"
                popper-class="prophesy_popover"
                class="mt10"
              >
                <div class="d_4 cp" slot="reference">
                  <div
                    class="had_select_time"
                    :style="item.isselecttime ? 'color:red' : ''"
                  >
                    {{ item.timeselect }}
                  </div>
                  <i class="el-icon-arrow-down"></i>
                </div>
                <div class="pop_content">
                  <div class="pop_top">
                    <div>请选择时间</div>
                    <div>视频总时长：{{ chapter_hour | formatTimeLength }}</div>
                  </div>
                  <el-time-picker
                    v-if="ishowPicker"
                    :clearable="false"
                    popper-class="prophesy_time_picker_content"
                    :ref="'timePicker' + index"
                    :picker-options="{
                      selectableRange: timeoption,
                    }"
                    @change="comformTime"
                    @blur="pickerBlur"
                    style="width: 100%"
                    placeholder="任意时间点"
                    v-model="item.timeselect2"
                    :default-value="timeDefaultShow"
                  ></el-time-picker>
                </div>
              </el-popover>

              <div class="d_2">
                <el-input
                  :autosize="{ minRows: 1, maxRows: 10 }"
                  @focus="facusInput($event, index)"
                  @input="changeInput(item, index)"
                  v-model="item.send_msg"
                  @blur="blurInput"
                  placeholder="请输入内容"
                  style="width: 400px"
                  class="mb10"
                  maxlength="200"
                  show-word-limit
                  type="textarea"
                ></el-input>
                <div class="btns" v-if="isFacus === index">
                  <el-button
                    type="text"
                    style="padding: 0"
                    @mousedown.native="fillAll(item.send_msg)"
                  >
                    填充到全部
                  </el-button>
                  <div class="driver">|</div>
                  <el-button
                    type="text"
                    style="padding: 0"
                    @mousedown.native="clearSinggle(index)"
                  >
                    清空该内容
                  </el-button>
                </div>
              </div>
              <div class="btns ml20">
                <el-button
                  type="text"
                  style="padding: 0"
                  @click="robotSpeak(index)"
                >
                  预发送
                </el-button>
                <div class="driver">|</div>

                <el-popconfirm
                  title="是否确定移除该机器人？"
                  @confirm="delRobot($event, item.robot_relation_id, index)"
                >
                  <el-button type="text" slot="reference" style="padding: 0">
                    移除
                  </el-button>
                </el-popconfirm>
              </div>
            </div>
          </template>
          <div class="empty" v-else-if="empty">暂无数据，请先添加机器人！</div>
        </div>
        <div class="allbtns" v-if="newRobotVal.length > 0">
          <el-button size="medium" @click="timeClear">清空时间</el-button>
          <el-button size="medium" @click="allClear">清空内容</el-button>
          <el-button
            size="medium"
            class="mr20"
            type="primary"
            @click="allrobotSpeak"
          >
            全部发送
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 添加机器人 -->
    <robot-select
      @robotSelectVal="robotSelectVal"
      v-if="robotSelectStatus"
      :dialogstatus.sync="robotSelectStatus"
    ></robot-select>
    <el-button id="popo-btn" ref="popo" />
  </div>
</template>

<script>
import robotSelect from '@/components/openCourse/robotSelect'
export default {
  name: 'prophesy',

  components: {
    robotSelect,
  },

  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    chapter_hour: {
      type: String,
      default: '',
    },
  },

  computed: {
    oldRobotVal() {
      return _.assign([], this.newRobotVal)
    },

    // 给时间空间添加默认值
    timeDefaultShow() {
      let curTime = new Date()
      curTime.setHours(0)
      curTime.setMinutes(0)
      curTime.setSeconds(0)
      return curTime
    },

    // 限制时间范围
    timeoption() {
      const val = this.$options.filters.formatTimeLength(this.chapter_hour)
      return '00:00:00 - ' + val
    },
    openClassId() {
      return this.$route.query.open_class_id
    },
  },

  created() {
    this.robotList()
  },

  data() {
    return {
      ishowPicker: true,

      timeselect2: '',

      isshow: false,

      selectIndex: '',

      empty: false,

      isFacus: '',

      newRobotVal: [],

      robotSelectStatus: false,
    }
  },

  methods: {
    //  单个预发言
    robotSpeak(index) {
      const list = this.newRobotVal[index]
      if (!list.send_msg) {
        this.$root.prompt({
          msg: '请先输入发送内容',
        })
        return
      }
      this.toPostrobotSpeak([list])
    },

    allrobotSpeak() {
      if (this.newRobotVal.some((item) => !item.send_msg)) {
        this.$root.prompt({
          msg: '请先输入发送内容',
        })
        return
      }
      this.toPostrobotSpeak(this.newRobotVal)
    },

    toPostrobotSpeak(list) {
      this.$http({
        url: '/openClass/robotSpeak',
        data: {
          open_class_id: this.openClassId,
          list: list,
        },
        callback: ({ info }) => {
          const arr = []

          this.newRobotVal.forEach((item) => {
            item.isselecttime = false
            arr.push(item)
          })

          this.newRobotVal = arr

          this.$root.prompt({
            msg: info,
            type: 'success',
          })

          this.$emit('robotSpeaksuccess')
        },
        error: ({ robot_relation_id }) => {
          const arr = []

          this.newRobotVal.forEach((item) => {
            if (item.robot_relation_id == robot_relation_id) {
              item.isselecttime = true
            } else {
              item.isselecttime = false
            }
            arr.push(item)
          })

          this.newRobotVal = arr
        },
      })
    },

    // 移除列表机器人
    delRobot(event, robot_relation_id, index) {
      console.log(event, robot_relation_id, index)
      this.$http({
        url: '/console/delRobot',
        data: {
          open_class_id: this.openClassId,
          robot_relation_id: robot_relation_id,
        },
        callback: ({ info }) => {
          this.$root.prompt({
            msg: info,
            type: 'success',
          })
          this.newRobotVal.splice(index, 1)
        },
      })
    },

    pickerBlur() {
      this.$refs.popo.$el.click()
    },

    comformTime(val) {
      // 选择的时长
      if (val) {
        this.oldtime = val
        const hh = val.getHours()
        const mm = val.getMinutes()
        const ss = val.getSeconds()
        this.timelenth = hh * 60 * 60 + mm * 60 + ss
        this.newRobotVal[this.selectIndex].timeselect =
          (hh >= 10 ? hh : '0' + hh) +
          ':' +
          (mm >= 10 ? mm : '0' + mm) +
          ':' +
          (ss >= 10 ? ss : '0' + ss)
        this.newRobotVal[this.selectIndex].send_time = this.timelenth
      }
      this.$refs.popo.$el.click()
    },

    pickerFocus() {
      this.$refs['timePicker' + this.selectIndex][0].focus()
    },

    popoverShow(event, index) {
      this.ishowPicker = true
      this.isshow = true
      this.selectIndex = index
      setTimeout(() => {
        this.pickerFocus()
      }, 500)
    },

    popoverHide() {
      this.ishowPicker = false
      this.isshow = false
    },

    // 单个清除
    clearSinggle(index) {
      this.newRobotVal[index].send_msg = ''
      this.msgSuccess()
    },

    msgSuccess() {
      this.$root.prompt({
        msg: '操作成功',
        type: 'success',
      })
    },

    // 全部清空时间
    timeClear() {
      this.newRobotVal.forEach((item) => {
        item.timeselect = '00:00:00'
      })
      this.isFacus = ''
      this.msgSuccess()
    },

    // 全部清空
    allClear() {
      this.newRobotVal.forEach((item) => {
        item.send_msg = ''
      })
      this.isFacus = ''
      this.msgSuccess()
    },

    // 填充到全部
    fillAll(text) {
      this.newRobotVal.forEach((item) => {
        item.send_msg = text
      })
      this.isFacus = ''
      this.msgSuccess()
    },

    // 更改输入框中的数据
    changeInput(data, index) {
      this.newRobotVal[index].send_msg = data.send_msg
      let arr = []
      this.newRobotVal.forEach((item) => {
        arr.push(item)
      })
      this.newRobotVal = arr
    },

    // 监听聚焦时间
    facusInput(event, index) {
      this.isFacus = index
    },

    blurInput() {
      this.isFacus = ''
    },

    cancel() {
      this.newRobotVal = []
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },

    // 获取选择的机器人并添加数据
    robotSelectVal(val) {
      const robotId = val.map((item) => item.robot_id)
      this.$http({
        url: '/console/addRobot',
        data: {
          open_class_id: this.openClassId,
          robot_id: robotId,
        },
        callback: () => {
          this.robotList()
        },
      })
    },

    // 获取控制台已选择的机器人列表
    robotList() {
      this.$http({
        url: '/console/robotList',
        data: {
          open_class_id: this.openClassId,
        },
        callback: ({ data }) => {
          let arr = []
          data.forEach((item) => {
            item.timeselect = '00:00:00'
            item.send_time = ''
            item.send_msg = ''
            arr.push(item)
          })

          this.newRobotVal.push(...arr)
          const endlist = []

          this.newRobotVal.forEach((item) => {
            if (
              endlist.every(
                (item1) => item1.robot_relation_id != item.robot_relation_id
              )
            ) {
              endlist.push(item)
            }
          })

          this.newRobotVal = endlist

          this.empty = data.length == 0
        },
      })
    },
  },
}
</script>

<style  lang="scss">
.prophesy_time_picker_content {
  width: 400px !important;
  .el-time-panel__btn.cancel {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
#popo-btn {
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin: 0;
}

.prophesy_popover {
  .pop_content {
    height: 325px !important;
    .pop_top {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #333333;
    }
  }
}
::v-deep .el-textarea__inner {
  min-height: 40px !important;
}
::v-deep .el-dialog {
  height: auto !important;
}
::-webkit-scrollbar {
  width: 2px !important;
  height: 8px !important;
  background-color: #fff !important;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
  background-color: #fff !important;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #ccc !important;
}
#prophesy {
  .mask {
    background-color: transparent !important;
  }
  img {
    width: 100%;
    height: 100%;
  }
  ::v-deep .dialogVisibleprophesy {
    .el-dialog__body {
      padding: 20px;
    }
    .dialogVisible_bottom {
      height: 448px;
      .d_1 {
        display: flex;
        align-items: center;
        width: 100px;
        .photo {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          border-radius: 2px;
        }
        .name {
          width: calc(100% - 30px);
          font-size: 14px;
          color: #333333;
          line-height: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .btns {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .driver {
          width: 1px;
          margin: 0 10px;
          color: $color;
        }
      }
      .d_2 {
        width: 390px;
        margin-left: 5px;
      }
      .d_4 {
        display: flex;
        align-items: center;
        margin-left: 20px;
        width: 90px;
        margin-left: 20px;
        .had_select_time {
          font-size: 14px;
          color: #0aa29b;
          margin-right: 10px;
        }
      }

      .d_3 {
        margin-left: 20px;
        width: calc(100% - 672px);
      }
      .header {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 14px;
        width: 100%;
        height: 42px;
        background: #f5f5f5;
        border-radius: 1px;
      }
      .allbtns {
        height: 70px;
        background: #ffffff;
        width: 800px;
        transform: translateX(-20px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.07);
        .el-button {
          color: #0aa29b;
          border-color: #0aa29b;
        }
        .el-button:last-child {
          color: #ffffff;
        }
      }
      .main_contain {
        overflow-y: auto;
        height: calc(100% - 150px);
        .message_contain {
          border-bottom: 1px #f5f5f5 solid;
          padding: 20px 20px 10px 20px;
          display: flex;
          align-items: flex-start;
        }
        .empty {
          font-size: 14px;
          color: #333333;
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    .clear_class {
      &.el-button {
        padding: 0;
        margin-right: 32px;
      }
    }
  }
}
</style>